<template>
    <app-header/>
        <div class="container-fluid p-0">
            <div class="d-flex topspace-90" dir="rtl">
                <app-side-bar/>
                <Suspense>
                    <template #default>
                        <app-vocabulary/>
                    </template>
                    <template #fallback>
                        <app-loading/>
                    </template>
                </Suspense>
            </div>
        </div>
    <app-footer/>
</template>

<script>
import AppFooter from '../../components/layout/AppFooter.vue'
import AppHeader from '../../components/layout/AppHeader.vue'
import AppSideBar from '../../components/layout/AppSideBar.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { defineAsyncComponent, Suspense } from "vue";
// import AppVocabulary from '../../components/Pages/AppVocabulary.vue'
const AppVocabulary = defineAsyncComponent(() =>
  import("../../components/Pages/AppVocabulary.vue")
);
export default {
  components: { AppHeader, AppSideBar, AppFooter, Suspense,AppLoading, AppVocabulary },

}
</script>

<style>

</style>